<template>
  <div v-if="itemClone" class="recipe-block">
    <locale-router-link
      :to="`recipes/${itemClone.id}`"
      class="recipe-block__img"
    >
      <img v-lazy="getImage" alt="Recipe image" />
    </locale-router-link>
    <div v-if="itemClone.attributes" class="recipe-block__descr">
      <locale-router-link
        v-if="itemClone.attributes.title"
        tag="h3"
        :to="`recipes/${itemClone.id}`"
        class="recipe-block__title"
      >
        {{ itemClone.attributes.title }}
      </locale-router-link>

      <div class="recipe-block__info">
        <div class="recipe-block__time">
          <div class="recipe-block__time-title">
            {{ $t("willReady") }}
          </div>
          <div
            v-if="itemClone.attributes.ETA"
            class="recipe-block__time-amount"
          >
            {{ itemClone.attributes.ETA }}
          </div>
        </div>
        <recipe-difficulty
          v-if="itemClone.attributes.difficulty"
          :difficultyNumber="itemClone.attributes.difficulty"
        />
      </div>
      <div class="recipe-block__ingredients">
        <div class="recipe-block__ingredients-title">
          {{ itemClone.attributes.recipe_details.length }}
          {{ $t("ingredientsAmount") }}
        </div>
        <div class="recipe-block__filter">
          <div class="recipe-block__filter-header">
            <div class="recipe-block__filter-title">
              {{ $t("ingredients") }}
            </div>
            <div class="recipe-block__portions">
              <div class="recipe-block__portions-title">
                {{ $t("portions") }}
              </div>
              <div class="recipe-block__portions-quantity">
                <button
                  class="recipe-block__portions-minus"
                  @click="updateIngredient('minus')"
                >
                  –
                </button>
                <p class="recipe-block__portions-amount">{{ countPortion }}</p>
                <!-- <input type="text" class="recipe-block__portions-amount" /> -->
                <button
                  class="recipe-block__portions-plus"
                  @click="updateIngredient('plus')"
                >
                  +
                </button>
              </div>
            </div>
          </div>
          <!-- <recipe-ingredients-list
            v-if="itemClone.attributes.recipe_details"
            :items="itemClone.attributes.recipe_details"
          /> -->
          <div
            v-if="itemClone.attributes.recipe_details"
            class="recipe-block__filter-items"
          >
            <div
              v-for="(item, index) in itemClone.attributes.recipe_details"
              :key="index"
              class="recipe-block__filter-item"
            >
              <div class="recipe-block__filter-item-name">
                {{ item.name }}
              </div>
              <span></span>
              <div class="recipe-block__filter-item-info">
                {{ item.quantityUpadated }} {{ $t(item.typeIngredient) }}
              </div>
            </div>
          </div>
          <locale-router-link
            :to="`recipes/${itemClone.id}`"
            class="recipe-block__filter-btn"
          >
            {{ $t("recipePage") }}
          </locale-router-link>
        </div>
      </div>
      <div v-if="itemClone.attributes.author.data" class="recipe-block__author">
        <div class="recipe-block__author-wrapper">
          <div class="recipe-block__author-img">
            <img
              :src="getAutorImage(itemClone)"
              alt="Author image"
              width="70"
              height="80"
            />
          </div>
          <div class="recipe-block__author-info">
            <div
              class="recipe-block__author-name"
              v-if="itemClone.attributes.author.data.attributes.name"
            >
              {{ itemClone.attributes.author.data.attributes.name }}
            </div>
            <!-- <div
              class="recipe-block__author-position"
              v-if="itemClone.attributes.author.data.attributes.whois"
            >
              {{ itemClone.attributes.author.data.attributes.whois }}
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RecipeCard",
  components: {
    RecipeDifficulty: () => import("@/components/recipe/RecipeDifficulty"),
  },
  props: {
    item: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      itemClone: null,
      countPortion: 1,
    };
  },

  computed: {
    getImage() {
      return this.$helpers.getAbsolutePath(
        this.itemClone?.attributes?.image?.data?.attributes?.url
      );
    },
  },
  watch: {
    item: {
      handler(item) {
        this.itemClone = JSON.parse(JSON.stringify(item));
      },
    },
  },
  mounted() {
    this.itemClone = JSON.parse(JSON.stringify(this.item));
    this.itemClone?.attributes?.recipe_details?.forEach((p) => {
      p.quantityUpadated = p.quantity;
      switch (p.type) {
        case "gramms":
          p.typeIngredient = "typeIngredient.gramms";
          break;
        case "items":
          p.typeIngredient = "typeIngredient.items";
          break;
        case "volume_ml":
          p.typeIngredient = "typeIngredient.volume_ml";
          break;
        case "tea_spoons":
          p.typeIngredient = "typeIngredient.tea_spoons";
          break;
        case "spoons":
          p.typeIngredient = "typeIngredient.spoons";
          break;
        default:
          this.showMagnum = true;
          break;
      }
    });
  },
  methods: {
    getAutorImage(item) {
      const urlImg =
        item?.attributes?.author?.data?.attributes?.avatar?.data?.attributes
          ?.url;
      return this.$helpers.getAbsolutePath(urlImg);
    },
    updateIngredient(type) {
      if (type === "plus") {
        this.countPortion++;
        this.itemClone?.attributes?.recipe_details?.forEach((p) => {
          if (p.quantityUpadated) {
            p.quantityUpadated = p.quantityUpadated + p.quantity;
          }
        });
      }
      if (type === "minus") {
        if (this.countPortion === 1) {
          this.countPortion = 1;
        } else {
          this.countPortion--;
          this.itemClone?.attributes?.recipe_details?.forEach((p) => {
            if (p.quantityUpadated) {
              p.quantityUpadated = p.quantityUpadated - p.quantity;
            }
          });
        }
      }
    },
  },
};
</script>
